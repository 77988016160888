import React from 'react'

function EarlyVoteSites({ electionData }) {
  const defaultValue = "Information Not Available Yet!"
  if (electionData.earlyVoteSites) {
    return (
      <div className='earlyVoteSites'>
        <h4 className='sectionTitle'>
          Early Vote Sites: <br />
        </h4>
        <div className='data'>
          {electionData.earlyVoteSites.map((pollingLocation) => (
            <div key={pollingLocation.address}>
              Address: {pollingLocation.address.line1 || defaultValue}<br />
              Hours: {pollingLocation.address.pollingHours || defaultValue}<br />
              Name: {pollingLocation.address.name || defaultValue}<br />
              Voter Services: {pollingLocation.address.voterServices || defaultValue}<br />
              Start Date: {pollingLocation.address.startDate || defaultValue}<br />
              End Date: {pollingLocation.address.endDate || defaultValue}<br /><br /><br />
            </div>
          ))}<br />
        </div>
      </div>
    )
  }
}

export default EarlyVoteSites