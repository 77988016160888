import React from 'react'

function ContestsReferendums({ electionData }) {
  const defaultValue = "Information Not Available Yet!"
  if (electionData.contests) {
    return (
      <div className='contestsReferendums'>
        <h4 className='sectionTitle'>
          Contests And Referendums: <br />                               
        </h4>
      <div className='data'>
        {electionData.contests.map((contest) => (
           <div key={contest.office}>
              Office: {contest.office || defaultValue}<br />
              District: {contest.district.name || defaultValue}<br />
              Candidates: <br />
                {/* Name: {contest.candidates.name || defaultValue}<br />
                Party: {contest.candidates.party || defaultValue}<br />
                Url: {contest.candidates.candidateUrl || defaultValue}<br />
                Phone: {contest.candidates.phone || defaultValue}<br />
                Photo Url: {contest.candidates.photoUrl || defaultValue}<br />
                Email: {contest.candidates.email || defaultValue}<br /> */}
              Referendums: <br />
                Title: {contest.referendumTitle || defaultValue}<br />
                Url: {contest.referendumUrl || defaultValue}<br />
                Brief: {contest.referendumBrief || defaultValue}<br />
                Pro Statement: {contest.referendumProStatement || defaultValue}<br />
                Con Statement: {contest.referendumConStatement || defaultValue}<br />
                Effect of Abstain: {contest.referendumEffectOfAbstain || defaultValue}<br />
                Passage Threshold: {contest.referendumPassageThreshold || defaultValue}<br /><br /><br />
          </div>
        ))}<br />
      </div>
      </div>
    )
  }
}

export default ContestsReferendums