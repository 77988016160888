import React from 'react'

function StateVoteInfo({ electionData }) {
  const defaultValue = "Information Not Available Yet!"
  if (electionData.state) {
    return (
      <div className='stateInfo'>
        <h4 className='sectionTitle'>
          State Voting Info: <br />
        </h4>
      <div className='data'>
        {electionData.state.map((stateInfo) => (
          <div key={stateInfo.name}>
            Name: {stateInfo.name || defaultValue}<br />
            Election Administration Body: {stateInfo.electionAdministrationBody.name || defaultValue}<br />
            Election Info: {stateInfo.electionAdministrationBody.electionInfoUrl || defaultValue}<br />
            Election Registration Url: {stateInfo.electionAdministrationBody.electionRegistrationUrl || defaultValue}<br />
            Election Registration Confirmation Url: {stateInfo.electionAdministrationBody.electionRegistrationConfirmationUrl || defaultValue}<br />
            Absentee Voting Info: {stateInfo.electionAdministrationBody.absenteeVotingInfoUrl || defaultValue}<br />
            Voting Location Finder Url: {stateInfo.electionAdministrationBody.votingLocationFinderUrl || defaultValue}<br />
            Ballot Info Url: {stateInfo.electionAdministrationBody.ballotInfoUrl || defaultValue}<br />
            Correspondence Address: {stateInfo.electionAdministrationBody.correspondenceAddress.line1 || defaultValue}<br />
                                    {stateInfo.electionAdministrationBody.correspondenceAddress.city || defaultValue}<br />
                                    {stateInfo.electionAdministrationBody.correspondenceAddress.state || defaultValue}<br />
                                    {stateInfo.electionAdministrationBody.correspondenceAddress.zip || defaultValue}<br />
            {/* Physical Address: {stateInfo.electionAdministrationBody.physicalAddress.line1 || defaultValue}<br /> */}
                                  {/* {stateInfo.electionAdministrationBody.physicalAddress.city || defaultValue}<br />
                                  {stateInfo.electionAdministrationBody.physicalAddress.state || defaultValue}<br />
                                  {stateInfo.electionAdministrationBody.physicalAddress.zip || defaultValue}<br /> */}
            Election Officials:
            {/* Name: {stateInfo.electionAdministrationBody.electionOfficials.name || defaultValue} */}
            {/* Title: {stateInfo.electionAdministrationBody.electionOfficials.title || defaultValue} */}
            {/* Office Phone Number:{stateInfo.electionAdministrationBody.electionOfficials.officePhoneNumber || defaultValue} */}
            {/* Fax Number: {stateInfo.electionOfficials.faxNumber || defaultValue} */}
            {/* Email Address: {stateInfo.electionAdministrationBody.electionOfficials.emailAddress || defaultValue}    */}
            </div>
        ))}<br /><br /><br />
      </div>
      </div>
    )
  }
}

export default StateVoteInfo