import React from 'react'

function IndependentExpenditures({ indExpResults }) {
  const defaultValue = "Information Not Available Yet!"
  if (indExpResults.children) {
    return (
      <div className='independentExpenditures'>
        <h4 className='sectionTitle'>
          Independent Expenditures: <br />
        </h4>
        <div className='data'>
          {indExpResults.children.map((expenditure) => (
            <div key={expenditure.attributes.pacshort}>
                Committee Name: {expenditure.attributes.pacshort || defaultValue}<br />
                Supports Or Opposes: {expenditure.attributes.suppopp || defaultValue}<br />
                Candidate Targeted: {expenditure.attributes.candname || defaultValue}<br />
                Amount: {expenditure.attributes.amount || defaultValue}<br />
                Note: {expenditure.attributes.note || defaultValue}<br />
                Party: {expenditure.attributes.party || defaultValue}<br />
                Payee: {expenditure.attributes.payee || defaultValue}<br />
                Date: {expenditure.attributes.date || defaultValue}<br /><br /><br />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default IndependentExpenditures