import React from 'react'

function AllStateLegislators({ openSecretsLegislators }) {
  const defaultValue = "Information Not Available Yet!"
  if (openSecretsLegislators.children) {
    return (
      <div className='allStateLegislators'>
        <h4 className='sectionTitle'>
          All State Legislators: <br />
        </h4>
        <div className='data'>
          {openSecretsLegislators.children.map((rep) => (
            <div key={rep.attributes.cid}>
              FirstName:{rep.attributes.firstlast || defaultValue}<br />
              Last Name: {rep.attributes.lastname || defaultValue}<br />
              Party: {rep.attributes.party || defaultValue}<br />
              Office: {rep.attributes.office || defaultValue}<br />
              Gender: {rep.attributes.gender || defaultValue}<br />
              First Elected To Office: {rep.attributes.firstelectoff || defaultValue}<br />
              Phone: {rep.attributes.phone || defaultValue}<br />
              Fax: {rep.attributes.fax || defaultValue}<br />
              Website: {rep.attributes.website || defaultValue}<br />
              Webform: {rep.attributes.webform || defaultValue}<br />
              Office Address: {rep.attributes.congress_office || defaultValue}<br />
              Bioguide ID: {rep.attributes.bioguide_id || defaultValue}<br />
              Votesmart ID: {rep.attributes.votesmart_id || defaultValue}<br />
              Federal Election Commission ID: {rep.attributes.feccandid || defaultValue}<br />
              Twitter ID: {rep.attributes.twitter_id || defaultValue}<br />
              Youtube URL: {rep.attributes.youtube_url || defaultValue}<br />
              Facebook ID: {rep.attributes.facebook_id || defaultValue}<br />
              Birthdate: {rep.attributes.birthdate || defaultValue}<br /><br /><br />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default AllStateLegislators