import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import './navigation.css'

function Navigation(props) {
  return (
    <div>
        <Navbar bg="light" expand="lg">
            <Container >
                <Navbar.Brand href="/"><span className='nav-color'>MTMG</span></Navbar.Brand>
                {/* <Navbar.Brand href="/">
                    <img
                        src="./assets/mtmgLogo.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="Logo"
                    />
                </Navbar.Brand> */}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/"><span className='nav-color'>Home</span></Nav.Link>
                        <Nav.Link href="/search"><span className='nav-color'>Search</span></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </div>
  )
}

export default Navigation