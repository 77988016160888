import React from 'react'

function LocalVoteInfo({ electionData }) {
  const defaultValue = "Information Not Available Yet!"
  if (electionData.state) {
    return (
      <div className='localInfo'>
        <h4 className='sectionTitle'>
          Local Voting Info: <br />
        </h4>
      {/* <div className='data'>
        {electionData.state.map((localInfo) => (
          <div key={localInfo.local_jurisdiction.name}>
            Name: {localInfo.local_jurisdiction.name || defaultValue}<br />
            Election Info Url: {localInfo.local_jurisdiction.electionAdministrationBody.electionInfoUrl || defaultValue}<br />
            Correspondence Address: {localInfo.local_jurisdiction.electionAdministrationBody.correspondenceAddress.line1 || defaultValue}<br />
                                        {localInfo.local_jurisdiction.electionAdministrationBody.correspondenceAddress.city || defaultValue}<br />
                                        {localInfo.local_jurisdiction.electionAdministrationBody.correspondenceAddress.state || defaultValue}<br />
                                        {localInfo.local_jurisdiction.electionAdministrationBody.correspondenceAddress.zip || defaultValue}<br />
            Physical Address: {localInfo.local_jurisdiction.electionAdministrationBody.physicalAddress.line1 || defaultValue}<br />
                              {localInfo.local_jurisdiction.electionAdministrationBody.physicalAddress.city || defaultValue}<br />
                              {localInfo.local_jurisdiction.electionAdministrationBody.physicalAddress.state || defaultValue}<br />
                              {localInfo.local_jurisdiction.electionAdministrationBody.physicalAddress.zip || defaultValue}<br />
            Election Officials: 
              Number: {localInfo.local_jurisdiction.electionOfficials.officePhoneNumber || defaultValue}<br />
              Email: {localInfo.local_jurisdiction.electionOfficials.emailAddress || defaultValue}<br />
          </div>
        ))}<br />              
      </div>                         */}
       </div>
    )
  }
}

export default LocalVoteInfo