import React from 'react'

function RepData({ repData }) {
  const defaultValue = "Information Not Available Yet!"
  if(repData.offices && repData.officials) {
  return (
    <div>
        <div className='offices'>
            <h4 className='sectionTitle'>
            Offices You Elect:
            </h4> 
            {repData.offices.map((office) => (
                <div key={office.name} className='data'>
                  {office.name || defaultValue}<br />
                </div>
          ))}
        </div>
        <div className='officials'>
            <h4 className='sectionTitle'>
            Officials Currently Elected:
            </h4> 
          {repData.officials.map((official) => (
                <div key={official.name} className='data'>
                  Photo: <img alt='Elected Official' src={official.photoUrl || defaultValue}/><br />
                  Name: {official.name || defaultValue}<br />
                  Party: {official.party || defaultValue}<br />
                  Email: {official.emails || defaultValue}<br />
                  Phone Number: {official.phones || defaultValue}<br />
                  URL: <a href={official.urls || defaultValue} target="_blank" rel='noreferrer'>{official.urls || defaultValue}</a><br /><br /><br />
                  {/* Address: {official.address.line1 || defaultValue}<br />
                  {official.address.city || defaultValue}<br />
                  {official.address.state || defaultValue}<br />
                  {official.address.zip || defaultValue}<br /> */}
                </div>
          ))}
        </div>
    </div>
  )
  }
}

export default RepData