import React, { useState } from 'react'
import AllStateLegislators from './AllStateLegislators';
import IndependentExpenditures from './IndependentExpenditures';
import RepData from './RepData';
import DropOffLocations from './DropOffLocations'
import PollingLocations from './PollingLocations'
import EarlyVoteSites from './EarlyVoteSites'
import StateVoteInfo from './StateVoteInfo'
import LocalVoteInfo from './LocalVoteInfo'
import ContestsReferendums from './ContestsReferendums'

function Search() {
    const initialState = {
		searchBarLine1: '',
		searchBarCity: '',
		searchBarState: '',
		searchBarZip: '',
	};
	
  const [formState, setFormState] = useState(initialState);
  const [repData, setRepData] = useState([])
  const [electionData, setElectionData] = useState([])
  const [indExpResults, setIndExpResults] = useState([])
  const [openSecretsLegislators, setOpenSecretsLegislators] = useState([])
  const XMLParser = require('react-xml-parser');

  
  const handleSubmit = (event) => {
		event.preventDefault();
		const endPoint = `${formState.searchBarLine1} ${formState.searchBarCity} ${formState.searchBarState} ${formState.searchBarZip}`
		const googleRepApi = `https://www.googleapis.com/civicinfo/v2/representatives?key=${process.env.REACT_APP_GOOGLE_API_KEY}&address=${endPoint}`;
		const googleElectionApi = `https://civicinfo.googleapis.com/civicinfo/v2/voterinfo?address=${endPoint}&electionId=2000&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
		
		const fetchData = async() => {
			const urls = [googleRepApi, googleElectionApi];
			  try{
				let res = await Promise.all(urls.map(e => fetch(e)))
				let resJson = await Promise.all(res.map(e => e.json()))
				resJson = resJson.map(e => e)
				setRepData(resJson[0])
				setElectionData(resJson[1])
				
			  }catch(err) {
				console.log(err)
			  }
		}

		fetch(`https://www.opensecrets.org/api/?method=independentExpend&apikey=${process.env.REACT_APP_OPEN_SECRETS_API_KEY}`, {"Content-Type": "application/xml; charset=utf-8"})
			.then((res) => res.text())
			.then((resJson) => {
		  	const indExpResults = new XMLParser().parseFromString(resJson);
		  	setIndExpResults(indExpResults)
			
		})
		.catch(console.error);	

		fetch(`https://www.opensecrets.org/api/?method=getLegislators&id=${formState.searchBarState}&apikey=${process.env.REACT_APP_OPEN_SECRETS_API_KEY}`, {"Content-Type": "application/xml; charset=utf-8"})
			.then((res) => res.text())
			.then((resJson) => {
		  	const openSecretsLegislatorsResults = new XMLParser().parseFromString(resJson);
		  	setOpenSecretsLegislators(openSecretsLegislatorsResults)
			
		})
		.catch(console.error);

		fetchData()
		setFormState(initialState);
	};

	const handleChange = (event) => {
		setFormState({ ...formState, [event.target.id]: event.target.value });
	};

  return (
    <div>
        <div className='searchForm'>
			Enter Your Address: {' '}
				<form onSubmit={handleSubmit}>
					<label htmlFor='searchBarLine1' className='searchBarLabel'>
						(5555 Example Dr)
					</label>
					<br />
					<input
						id='searchBarLine1'
						onChange={handleChange}
						value={formState.searchBarLine1}
						className='searchBarInput'
						/><br />
					<label htmlFor='searchBarCity' className='searchBarLabel'>
						(Example City)
					</label>
					<br />
					<input
						id='searchBarCity'
						onChange={handleChange}
						value={formState.searchBarCity}
						className='searchBarInput'
					/><br />
					<label htmlFor='searchBarState' className='searchBarLabel'>
						(XX I.E. State)
					</label>
					<br />
					<input
						id='searchBarState'
						onChange={handleChange}
						value={formState.searchBarState}
						className='searchBarInput'
					/><br />
					<label htmlFor='searchBarZip' className='searchBarLabel'>
						(55555)
					</label>
					<br />
					<input
						id='searchBarZip'
						onChange={handleChange}
						value={formState.searchBarZip}
						className='searchBarInput'
					/><br />
					<button type='submit' className='submitBtn'>Submit</button>
				</form>
			</div>
            {/* components */}
			<RepData repData={repData}/>
			<PollingLocations electionData={electionData}/>
			<EarlyVoteSites electionData={electionData}/>
			<DropOffLocations electionData={electionData}/>
			<StateVoteInfo electionData={electionData}/>
			<LocalVoteInfo electionData={electionData}/>
			<ContestsReferendums electionData={electionData}/>
			<IndependentExpenditures indExpResults={indExpResults}/>
			<AllStateLegislators openSecretsLegislators={openSecretsLegislators}/>
    </div>
  )
}

export default Search