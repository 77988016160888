import React from 'react'

function DropOffLocations({ electionData }) {
  const defaultValue = "Information Not Available Yet!"
  if (electionData.dropOffLocations) {
    return (
      <div className='dropOffLocations'>
        <h4 className='sectionTitle'>
           Drop Off Locations: <br />
        </h4>
      <div className='data'>
        {electionData.dropOffLocations.map((pollingLocation) => (
          <div key={pollingLocation.address}>
            Address: {pollingLocation.address.line1 || defaultValue}<br />
            Hours: {pollingLocation.address.pollingHours || defaultValue}<br />
            Name: {pollingLocation.address.name || defaultValue}<br />
            Voter Services: {pollingLocation.address.voterServices || defaultValue}<br />
            Start Date: {pollingLocation.address.startDate || defaultValue}<br />
            End Date: {pollingLocation.address.endDate || defaultValue}<br /><br /><br />
          </div>
        ))}<br />
      </div>
      </div>
    )
  }
}

export default DropOffLocations