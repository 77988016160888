import React from 'react';
import './App.css';
import { Route, Routes } from "react-router-dom"
import Home from "./Home"
import Search from "./Search"
import Navigation from './Navigation'

function App() {
  return (
    <div className="App">
      <Navigation />
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/search' element={<Search />}/>
      </Routes>
    </div>
  );
}

export default App;
